import React from "react"

// Taken from CV circa 2007
const Short = () => (
  <section>
    <h4>Unipro In Short</h4>
    <h5>1998-2001 Unipro Group</h5>
    <p>A small-medium sized software development house focusing on human resources and payroll
      software principally for the international market.</p>
    <p>Unipro was ISO 9000 accredited. The development process had to be documented and to be
      justifiable in terms of quality. A DSDM was used with a short (two week) development-test-
      release cycle.</p>
    <h6>1998 Trainee Visual Basic Programmer</h6>
    <ul>
      <li>Learnt VB, ADO and SQL (including Oracle PL/SQL).</li>
      <li>Developed object oriented data access framework.</li>
    </ul>
    <p>
      Trainee Visual Basic Programmer
      UniPro
      1998 – 1998less than a year

      Bradford, United Kingdom
    </p>
    <p>
      Began to learn commercial development on the job in a small software house
      focused on Human Resources.
    </p>
    <p>
      I knew Spectrum BASIC so Visual Basic came easily. In my school days,
      my brother and I coded many programmes in BASIC and assembler. After
      returning from University, I taught myself Objective C to amuse myself
      on the Mac.
    </p>
    <p>
      Learnt
      - Visual Basic (VB)
      - Object oriented programming (OOP)
      - Object relational (OR) mapping using ADO
      - SQL and PL/SQL against Oracle RDBMS
    </p>
    <h6>1998-2000 Software Engineer</h6>
    <ul>
      <li>Worked on the design and implementation of the core module framework.</li>
      <li>Responsible for the technical side of the release cycle including product releases. As
        part of a small team, established the development process. Documented and
        presented this system to ISO9000 inspectors.
      </li>
      <li>Responsible for the software build process including development of custom build
        tools.
      </li>
    </ul>
    Software Engineer
    UniPro
    1998 – 2000
    <p>
      Human resources software in an small, accredited agile software house.
    </p>
    <p>
      My love of small team agile development started here. We established,
      developed and documented a process using the Dynamic System Development
      Method (DSDM). We presented this to the ISO9000 inspectors and regained
      our accreditation. The short cycle with weekly integration and feedback
      drove up quality from a young and inexperienced development team.
    </p><p>
    On the coding side, I focused on developing a generative object relational
    framework, the core module system and build tooling but with exposure to a
    variety of other technologies and techniques. Variety is one of the things
    I like about working for small companies.
  </p><p>
    Good times for me.
  </p>
    <p>
      Experiences
      - ISO9000 accreditation
      - Dynamic Systems Development Method (DSDM)
      - Visual Basic (VB)
      - Object oriented programming (OOP)
      - Object relational (OR) mapping using ADO
      - SQL and PL/SQL against Oracle RDBMS
      - XML, XSLT, SAX and DOM
      - HTTP and HTML
      - Interviewing
    </p>

    <h6>2000-2001 Retained in skeleton UK team then Self-Employed Consultant</h6>
    <ul>
      <li>Supported customers by email and maintained existing code.</li>
      <li>Day-to-day administration of Oracle 8i databases and Red Hat Linux database servers
        (including an upgrade).
      </li>
      <li>Maintained the network, NT servers and Oracle 8i databases running Red Hat Linux.</li>
      <li>Trained and mentored new staff.</li>
      <li>Assisted with transfer of development to South Africa.</li>
    </ul>
    Consultant UniPro 2000 – 2001
    <p>
      Retention in a skeleton team gave me the chance to sample new skills.
    </p><p>
    UniPro experienced cash flow difficulties at product roll out. The group
    was saved by a reorganization with a downsized UK presence. We completed
    the existing contract, maintained the infrastructure and transferred
    operations to South Africa.
  </p>
    <p>
      Lots of work but the final sign off was a satisfying moment.
    </p>
    <p>
      Experiences
      - Training
      - Mentoring
      - Customer support
      - NT (administration)
      - Oracle (administration)
      - Red Hat Linux (administration)
      - Network (administration)
      - Visual Basic (VB)
      - Object oriented programming (OOP)
      - Offshore collaboration
    </p>
    <h5>Unipro 2000</h5>
    <p>
      Web (Apache Webserver, HTML, XML, Servlets)
    </p>
    <p>This year I've been looking a bit more actively into web servers and server-side architectures.
      I installed and configured Apache on NT to provide a central intranet source for support and
      development documentation. THis project involved the creation of content for the site.
      I also used JDeveloper to develop servlets to run vai Apache JServ, with a view to automating our
      development quality control process.</p>
    <h5> 1998-2000 Unipro</h5>
    <p>I hav been lucky enough to be involved in a complete product life cycle in a relatively small team.
      The project involved a complete rewrite and replacement of all existing visual basic code whilst
      maintaining compatibility with existing legacy C++ code with existing database structures. The aim
      was to develop an objected oriented architecture which would allow rapid development of modern GUI driven
      windows modules based on the functionality of an existing unix product. The architecture had to be flexible
      enough to allow easy translation and customisation on site and also be capable of supporting both
      SQL Server and Oracle versions. The primary development focus was Oracle using ADO. I began workig on the
      data access elements. As the project matured, I became more focussed on the module development framework
      including the major design work.</p>
    <p>
      In June we were able to deliver the system before the deadline to our principlen customer for installation
      on the test site. The system passed this test and is currently being rolled out to several dozen sites.
    </p>
  </section>
)

export default Short
