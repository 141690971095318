import React from "react"
import Short from "./unipro/short"
import Full from "./unipro/full"

const Unipro = () => (
  <section>
    <h1>Unipro</h1>
    <Full/>
    <hr/>
    <Short/>
  </section>
)

export default Unipro