import React from "react"
import Experiences from "../experiences"

// Taken from fragment before cutting down to size
const Full = () => (
  <section>
    <h4>Unipro In Full</h4>
    <h5>LinkedIn</h5>
    <h6>Trainee Visual Basic Programmer
      UniPro

      1998 – 1998 less than a year</h6>
    <p>Began to learn commercial development on the job in a small software house focussed on Human Resources.</p>

    <p>I knew Spectrum BASIC so Visual Basic came easily.</p>

    <Experiences title="Learnt" bullets={
      [
        { point: "Visual Basic (VB)" },
        { point: "Object oriented programming (OOP)" },
        { point: "Object relational (OR) mapping using ADO" },
        { point: "SQL and PL/SQL against Oracle RDBMS" },
      ]}/>

    <h6>Software Engineer
      UniPro

      1998 – 20002 years</h6>

    <p>Bradford, United Kingdom</p>

    <p>Human resources software in an small, accredited agile software house.</p>

    <p>My love of small team agile development started here. We established, developed and documented a process using
      the Dynamic System Development Method (DSDM). We presented this to the ISO9000 inspectors and regained our
      accreditation. The short cycle with weekly integration and feedback drove up quality from a young and
      inexperienced development team. On the coding side, I focussed on developing a generative object relational
      framework, the core module system and build tooling but with exposure to a variety of other technologies and
      techniques. Variety is one of the things I like about working for small companies.</p>

    <p>Good times for me.</p>

    <Experiences title={"Experiences"} bullets={
      [
        { point: "ISO9000 accreditation" },
        { point: "Dynamic Systems Development Method (DSDM)" },
        { point: "Visual Basic (VB)" },
        { point: "Object oriented programming (OOP)" },
        { point: "Object relational (OR) mapping using ADO" },
        { point: "SQL and PL/SQL against Oracle RDBMS" },
        { point: "Object oriented programming (OOP)" },
        { point: "XML, XSLT, SAX and DOM" },
        { point: "HTTP and HTML" },
        { point: "Interviewing" },
      ]}/>
    <h6>
      Consultant
      UniPro

      2000 – 20011 year

      Bradford, United Kingdom</h6>
    <p>Retention in a skeleton team gave me the chance to sample new skills.</p>

    <p>UniPro experienced cash flow difficulties at product roll out. The group was saved by a reorganization with a
      downsized UK presence. We completed the existing contract, maintained the infrastructure and transferred
      operations to South Africa.</p>

    <p>Lots of work but the final sign off was a satisfying moment.</p>
    <Experiences title={"Experiences"} bullets={
      [
        { point: "Training" },
        { point: "Mentoring" },
        { point: "Customer support" },
        { point: "NT (administration)" },
        { point: "Oracle (administration)" },
        { point: "Red Hat Linux (administration)" },
        { point: "Network (administration)" },
        { point: "Visual Basic (VB)" },
        { point: "Object oriented programming (OOP)" },
        { point: "Offshore collaboration" },
      ]}/>

    <hr/>
    <h5>[SHORT]</h5>

    <h6>1998-2001 Unipro Group</h6>
    <p>
      A small-medium sized software development house focusing on human resources and payroll software principally for
      the international market.
    </p><p>
    Unipro was ISO 9000 accredited. The development process had to be documented and to be justifiable in terms of
    quality. A DSDM was used with a short (two week) development-test-release cycle.
  </p>

    <h6>1998 Trainee Visual Basic Programmer</h6>
    <ul>
      <li>Learnt VB, ADO and SQL (including Oracle PL/SQL). Developed object oriented data access framework.</li>
    </ul>

    <h6>1998-2000 Software Engineer</h6>
    <ul>
      <li>Worked on the design and implementation of the core module framework.</li>
      <li>Responsible for the technical side of the release cycle including product releases. As part of a small team,
        established the development process. Documented and presented this system to ISO9000 inspectors.
      </li>
      <li>Responsible for the software build process including development of custom build tools.</li>
    </ul>

    <h6>2000-2001 Software Engineer (retained in skeleton UK team) then Self-Employed Consultant</h6>
    <ul>
      <li>Supported customers by email and maintained existing code.</li>
      <li>Day-to-day administration of Oracle 8i databases and Red Hat Linux database servers (including an upgrade).
      </li>
      <li>Maintained the network, NT servers and Oracle 8i databases running Red Hat Linux.</li>
      <li>Trained and mentored new staff.</li>
      <li>Assisted with transfer of development to South Africa.</li>
    </ul>

    <h5>[ORIGINAL]</h5>

    <h6>1998-2001 Unipro Group</h6>
    <p>
      A small-medium sized software development house focusing on human resources and payroll software principally for
      the international market.
    </p><p>
    Hired as a visual basic trainee at the start of the complete rewrite of all existing HR code using object oriented
    techniques whilst retaining compatibility with existing legacy C++ code and database structures.
  </p><p>
    Unipro was ISO 9000 accredited. The development process had to be documented and to be justifiable in terms of
    quality. A DSDM was used with a short (two week) development-test-release cycle.
  </p>


    <h6>Trainee Visual Basic Programmer

      1998 April-1998 December Unipro (Software) Ltd.</h6>
    <ul>
      <li>Focused on the data access framework. Learnt ADO and SQL (including Oracle PL/SQL) as well as Visual Basic.
      </li>
    </ul>


    <h6>Software Engineer 1998 December-2000 September Unipro (Software) Ltd.</h6>

    <ul>
      <li>Participated in the design and implementation of the core module framework. Continued improving and extending
        the
        data access framework.
      </li>
      <li>Responsible for the technical side of the release cycle including product releases. As part of a small team,
        established the development process. Documented and presented this system to ISO9000 inspectors.
      </li>
      <li>Responsible for the software build process including development of custom build tools.</li>
    </ul>


    <h6>Software Engineer

      2000 September-January 2001 Unipro Enterprise Software</h6>

    <p>The Unipro Group was reorganized during the summer (soon after the first product rolls outs). The United Kingdom
      operation was downsized. Retained as a member of a very small skeleton team to ensure that existing contracts were
      fulfilled and existing customers supported through this transitional phase. This role demanded a different range
      of
      skills.</p>
    <ul>

      <li>Supported customers by email.</li>

      <li>Day-to-day administration of Oracle 8i databases and Red Hat Linux database servers (including an upgrade).
      </li>


      <li>Maintained the network and NT servers.</li>


      <li>Maintained existing Visual Basic code.</li>

      <li>Trained new staff.</li>
    </ul>

    <h6>Freelance

      2001 January - 2001 April Unipro Enterprise Software</h6>

    <p>Assisted with transfer of development to South Africa.</p>


  </section>
)

export default Full