import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Unipro from "../../components/unipro"

const UniproPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>

    <Unipro/>
  </Layout>
)

export default UniproPage
